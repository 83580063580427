<template>
  <div>
    <section id="mainContent" class="relative w-screen min-h-screen pointer-events-none flex items-center">
      <div id="contentHolder" class="w-screen dkblue pointer-events-auto">
        <div>
          <div v-show="!notifySet">
            <h1>You're signed up.</h1>
            <p>
              Now that you're signed up, let us know when you would like to get notified.
            </p>
            <div class="mx-auto text-center items-center">
              <button class="btn bg-ltblue rounded-sm" @click="notifyMorning" tag="button">7:00am</button>
              <button class="btn bg-ltblue rounded-sm" @click="notifyEvening" tag="button">9:00pm</button>
              <button class="btn bg-ltblue rounded-sm" @click="notifyBoth" tag="button">Both</button>
              <!-- <router-link class="btn bg-ltblue rounded-sm" to="/learnmore" tag="button">LEARN MORE</router-link> -->
            </div>
          </div>
          <div v-show="notifySet">
            <h1>You are all set up!</h1>
            <p>
              Your web browser will now let you know when your next active session is about to begin.
            </p>
            <div class="mx-auto text-center items-center">
              <router-link class="btn bg-ltblue rounded-sm" to="/" tag="button">RETURN TO HOME</router-link>
            </div>
          </div>
          <p v-html="responseMsg"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import 'tailwindcss/tailwind.css';
// import TimezoneDD from '@/components/timezonedd.vue';
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      responseMsg: '',
      loggedIn: true,
      notifySet: false,
    };
  },
  mounted() {
    // const tOffset = -(new Date().getTimezoneOffset() / 60);
    // document.querySelector('#timezone').value = String(tOffset);
    // console.log(String(tOffset));
      var tokenAvail = window.localStorage.length;
      console.log(tokenAvail);
      if (tokenAvail < 1) {
        this.$router.push('/');
      }

    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.loggedIn = newVal;
          this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {
    // save category information
    saveNotifySession: function(token, e) {
      this.checkSession(token, e);
      // var catNum = window.localStorage.length == 5 ? 0 : window.localStorage.length + 1;
      // var categoryName = 'category' + catNum;
      // console.log('saving category:', categoryName, newCategory);
    },
    notifyMorning: function(e) {
      this.saveNotifySession('morning', e);
    },
    notifyEvening: function(e) {
      this.saveNotifySession('evening', e);
    },
    notifyBoth: function(e) {
      this.saveNotifySession('both', e);
    },
    initNotification(token) {
      // var options = {
      //   body: 'You will be notified(TBD)',
      //   data: 'Shared reflection session.',
      // };
      var data = JSON.stringify({
        NotifyTime: token,
        token: this.$store.state.user.token,
      });

      var config = {
        method: 'post',
        url: this.$store.state.authURL + '/update',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      //   return await strapi.query('user', 'users-permissions').update({id: user_id}, {role: role_id});}

      axios(config)
        .then(function(response) {
          window.localStorage.setItem('userNotify', response.token);
          this.onNotifyConfirm(response);
          // notify = new Notification('You have turned your notifications on', options);
          // notify();
        })
        .catch(this.onNotifyConfirm);
        // .catch(this.onNotifyError);
    },
    checkNotify: function(token) {
      null(token);
      this.notifySet = true;
      this.initNotification(token);

      // if (!('Notification' in window)) {
      //   this.responseMsg = 'Unfortunately your browser does not support desktop notificatios.';
      //   return;
      // } else {
      //   Notification.requestPermission().then(function(permission) {
      //     // If the user accepts, let's create a notification
      //     if (permission === 'granted') {
      //       this.initNotification(token, new Notification());
      //     } else {
      //       this.responseMsg =
      //         'You have selected to not be notified. Please set reminders on your own to join in our shared reflection sessions.';
      //     }
      //   });
      // }
    },
    checkSession: function(token) {
      var tokenAvail = window.localStorage.length;
      if (tokenAvail > 0) {
        let userToken = localStorage.getItem('userNotify');
        if (userToken) {
          this.notifySet = true;
          // this.responseMsg = JSON.stringify(`You are already set`);
          //   this.$store.dispatch('setUserStatus', { target: 'authenticated', status: true });
          // this.$router.push('/');
        } else {
          this.checkNotify(token);
        }
      }
      // else {
      //   this.$router.push('/login/');
      // }

    },
    onNotifyConfirm: function(response) {
      // Handle success.
      console.log(response);
      //   console.log('Well done!');
      console.log('User profile', response.data.user);
      console.log('User token', response.data.jwt);
      this.$store.dispatch('setUserStatus', { target: 'authenticated', status: true });
      this.$store.dispatch('setUserStatus', { target: 'username', status: response.data.user.username });
      this.$store.dispatch('setUserStatus', { target: 'token', status: response.data.jwt });
      this.saveSession(response.data.jwt);
      this.$router.push('/signedin');
    },
    onNotifyError: function(error) {
      console.log(error);
      this.responseMsg = JSON.stringify(
        `We were unable to set up your notifications at this time. Please share this information with our admin:\n ${JSON.stringify(
          error
        )}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/helpers';
@import '../styles/typovars';

section {
  position: relative;
}
button {
  margin: 0px 21px 15px 0px;
}
#firstName {
  margin-right: 7px;
}
#lastName {
  margin-left: 7px;
}
</style>
